import GoogleSignin from 'app/components/Common/GoogleSignin';
import dynamic from 'next/dynamic';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { openChildInput, openInput, togglePreventEnter, togglePreventFocus } from '../../../actions/baseActions';
import { doSearchHotels } from '../../../actions/hotelActions';
import Footer from './MainLayout/Footer';
import Header from './MainLayout/Header';

const ProgressLoading = dynamic(() => import('../Passhtru/ProgressLoading'), { ssr: false });
const BodyHighlight = dynamic(() => import('../Common/BodyHighlight'));
const CookieNotice = dynamic(() => import('../Common/CookieNotice'));

type InputName = {
	inputs: string[];
	childInputs: Record<string, string>;
};
const inputNames: Record<string, InputName> = {
	hotels: {
		inputs: ['hotel-destination', 'check-in-date', 'check-out-date', 'room-type'],
		childInputs: {},
	},
};
interface MainLayoutProps {
	children: React.ReactNode;
	removeHeader?: boolean;
	removeFooter?: boolean;
}
const MainLayout: FC<MainLayoutProps & MainLayoutReduxProps> = (props) => {
	const [showGoogleOneTap, setShowGoogleOneTap] = useState(false);
	const {
		countryCode,
		partner,
		lng,
		enableProgressLoading,
		enabledVert,
		removeFooter,
		removeHeader,
		children,
		preventEnter,
	} = props;

	const onTabPressed = (e: KeyboardEvent) => {
		if (preventEnter) {
			props.togglePreventEnter(false);
			return true;
		}
		props.togglePreventFocus(false);

		const INPUTS = inputNames[enabledVert].inputs;
		const CHILD_INPUTS = inputNames[enabledVert].childInputs;

		let openedInput;
		const openedInputId = document.activeElement?.id;

		if (document.hasFocus()) {
			openedInput = openedInputId?.replace('-input', '');
		}

		if (!openedInput) {
			openedInput = props.openedInput;
		}
		switch (e.key) {
			// Tab
			case 'Tab':
				if (INPUTS.indexOf(openedInput) !== -1) {
					if (CHILD_INPUTS[openedInput]) {
						props.openChildInput(CHILD_INPUTS[openedInput]);
					}
					props.openInput(openedInput);
				}
				if (INPUTS.indexOf(openedInput) === -1 && openedInputId !== '') {
					props.openInput(null);
					props.openChildInput(null);
				}
				break;
			// Esc
			case 'Escape':
				props.openInput(null);
				props.openChildInput(null);
				break;
			// Enter
			case 'Enter':
				if (
					(INPUTS.indexOf(openedInput) !== -1 || openedInputId === '') &&
					['date-from', 'date-to'].indexOf(openedInput) === -1
				) {
					switch (props.enabledVert) {
						case 'hotels':
							props.doSearchHotels();
							break;
						default:
							break;
					}
				}
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		window.addEventListener('keyup', onTabPressed);
		return () => {
			window.removeEventListener('keyup', onTabPressed);
		};
	}, []);

	useEffect(() => {
		if (countryCode) {
			const isFromFrance = countryCode === 'FR';

			if (!isFromFrance) {
				setShowGoogleOneTap(true);
			}
		}
	}, [countryCode]);

	return (
		<div className={lng} id="layout">
			{showGoogleOneTap && <GoogleSignin />}
			{/* <GoogleSignin /> */}

			<ProgressLoading partner={partner} />

			<div
				style={{
					display: enableProgressLoading ? 'none' : 'block',
				}}
			>
				<BodyHighlight />
				<CookieNotice />
				{!removeHeader ? <Header /> : null}
				{children}
				{!removeFooter ? <Footer /> : null}
			</div>
		</div>
	);
};
const mapDispatchToProps = { openInput, openChildInput, doSearchHotels, togglePreventEnter, togglePreventFocus };

const mapStateToProps = (state: any) => ({
	enabledVert: state.base.enabledVert,
	lng: state.base.lng,
	openedInput: state.base.openedInput,
	preventEnter: state.base.preventEnter,
	enableProgressLoading: state.base.enableProgressLoading,
	partner: state.passthru.partner,
	countryCode: state.base.countryCode,
});
type MainLayoutReduxProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
