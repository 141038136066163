import { formatISO } from 'date-fns';
import { remove } from 'remove-accents';
import { catchErrorSync } from '../app/components/Common/AppErrorBoundary';
import Helper from '../app/modules/Helper';
import store from '../store';
import { getFbClientId } from '../utils/cookies';

import {
	emitGMTEvent,
	hideAndShowErros,
	sendDataToCompare,
	sendDataToPassthruForm,
	setTicketData,
	setTooltipError,
	submitForm,
	togglePriceAlertSignUpModal,
	toggleProgressLoading,
} from './baseActions';
import { saveFrontFormDataToSessionStorage } from './baseActions.utils';
import { callKayakCompareAndSaveResultsToState } from './compareActions';
import {
	HOTEL_ADD_ROOM,
	HOTEL_SET_CHECK_IN_DATE,
	HOTEL_SET_CHECK_OUT_DATE,
	HOTEL_SET_DESTINATION,
	HOTEL_SET_ROOM_TYPE,
	HOTEL_UPDATE_ROOMS,
} from './types';

export function getCarLocation(obj) {
	let pickupDestinationObj = obj.iata;
	if (!pickupDestinationObj) {
		pickupDestinationObj = [];
		pickupDestinationObj.push(obj.name);
		if (obj.stateCode) {
			pickupDestinationObj.push(obj.stateCode);
		}

		if (obj.state) {
			pickupDestinationObj.push(obj.state);
		}

		if (obj.country) {
			pickupDestinationObj.push(obj.country);
		}
		pickupDestinationObj = pickupDestinationObj.join(',');
	}
	return pickupDestinationObj;
}
export const selectHotelDestination = (destination) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: HOTEL_SET_DESTINATION,
			payload: destination,
		});
		const errors = store.getState().base.tooltipErrors;
		delete errors['hotel-destination'];
		store.dispatch(setTooltipError(errors));
	});

export const setCheckInDate = (date) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: HOTEL_SET_CHECK_IN_DATE,
			payload: date,
		});
	});

export const setCheckOutDate = (date) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: HOTEL_SET_CHECK_OUT_DATE,
			payload: date,
		});
	});

export const setRoomType = (roomType) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: HOTEL_SET_ROOM_TYPE,
			payload: roomType,
		});
	});

export const addRoom = () =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: HOTEL_ADD_ROOM,
			payload: {
				adults: 1,
				children: 0,
			},
		});
	});

export const updateRooms = (rooms) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: HOTEL_UPDATE_ROOMS,
			payload: rooms,
		});
	});

export const checkHotelDestination = (errors) => {
	const hotelDestination = store.getState().hotel.hotelDestination;
	if (Helper.isEmpty(hotelDestination)) {
		errors['hotel-destination'] = Helper.trans('validation.hotels.hotelDestination');
	}
};

export const doSearchHotels = (compare) =>
	catchErrorSync(() => {
		compare = compare || false;
		const errors = {};

		checkHotelDestination(errors);

		store.dispatch(setTooltipError(errors));
		const baseState = store.getState().base;
		if (
			Helper.isEmpty(errors) &&
			!baseState.isClosedPriceAlertSignUpModal &&
			baseState.priceAlertHandlerChecked &&
			document.body.clientWidth >= 992
		) {
			store.dispatch(togglePriceAlertSignUpModal(true));
			return;
		}
		if (Helper.isEmpty(errors)) {
			store.dispatch(emitGMTEvent(store.getState().base.enabledVert));
			const parsed = Helper.parseQueryString(window.location.search, true);
			let preventOpenFrontTab = false;
			if (parsed.hasOwnProperty('f') && parsed.f === '0') {
				preventOpenFrontTab = true;
			}
			let openBackTabTimeout = 500;
			if (preventOpenFrontTab) {
				openBackTabTimeout = 100;
			}

			const frontFromData = store.dispatch(getFormData('front'));
			saveFrontFormDataToSessionStorage(frontFromData);

			setTimeout(() => {
				if (compare) {
					store.dispatch(sendDataToCompare(frontFromData));
				} else {
					if (!preventOpenFrontTab) {
						store.dispatch(submitFormFront());
					}
					setTimeout(() => {
						store.dispatch(toggleProgressLoading(true));
						store.dispatch(submitFormBack());
					}, openBackTabTimeout);
				}
			}, 10);
		} else {
			store.dispatch(hideAndShowErros());
		}
	});

export const doSearchPartners = (isKcForm = false) =>
	catchErrorSync(() => {
		const errors = {};
		checkHotelDestination(errors);
		store.dispatch(setTooltipError(errors));
		if (!Helper.isEmpty(errors)) {
			return store.dispatch(hideAndShowErros());
		}

		const baseState = store.getState().base;
		if (
			Helper.isEmpty(errors) &&
			!baseState.isClosedPriceAlertSignUpModal &&
			baseState.priceAlertHandlerChecked &&
			document.body.clientWidth >= 992
		) {
			store.dispatch(togglePriceAlertSignUpModal(true));
			return;
		}

		const frontFromData = store.dispatch(getFormData('front'));
		for (const key in frontFromData) {
			if (key.startsWith('_')) {
				const newKey = key.replace('_', '');
				const dataArray = frontFromData[key].split('|');
				// sum adults and children
				frontFromData[newKey] = dataArray.map((e) => parseInt(e, 10)).reduce((acc, a) => acc + a, 0);
				delete frontFromData[key];
			}
		}
		callKayakCompareAndSaveResultsToState(frontFromData, isKcForm);
	});

export const submitFormBack = () =>
	catchErrorSync(() => {
		const form = document.getElementById('redirect-back-form');
		const data = store.dispatch(getFormData('back'));
		store.dispatch(setTicketData(data));
		store.dispatch(submitForm(form, data));
	});

export const submitFormFront = () =>
	catchErrorSync(() => {
		store.dispatch(sendDataToPassthruForm(store.dispatch(getFormData('front'))));
	});

export const getFormData = (formType) => {
	return catchErrorSync(() => {
		const data = store.getState().hotel;
		const destination = data.hotelDestination;
		const fromDate = data.checkInDate;
		const toDate = data.checkOutDate;
		const rooms = data.rooms;
		let pickupDestinationID = null;
		let pickupDestinationKey = null;
		let city = '';

		// map destination  for kayak search
		switch (destination.kayakType) {
			case 'ap':
				pickupDestinationID = destination.iata;
				pickupDestinationKey = 'airport';
				city = destination.city;
				break;
			case 'city':
				pickupDestinationID = 'C:' + destination.ptid.substr(1);
				pickupDestinationKey = 'city';
				city = destination.city;
				break;
			case 'hotel':
				pickupDestinationID = 'H:' + destination.ptid.substr(1);
				pickupDestinationKey = 'hotel';
				city = destination.city;
				break;
			default:
				break;
		}
		// map for prepopulate
		if (destination.kayakType === undefined) {
			switch (destination.type) {
				case 'city':
					pickupDestinationID = destination.cityID;
					pickupDestinationKey = 'city';
					city = destination.name;
					break;
				case 'hotels':
					pickupDestinationID = destination.cityID;
					pickupDestinationKey = 'hotel';
					city = destination.city.raw;
					break;
				default: // map airport
					pickupDestinationID = destination.iata;
					pickupDestinationKey = 'airport';
					break;
			}
		}

		const adults = rooms
			.map((item) => {
				return item.adults;
			})
			.join('|');
		const children = rooms
			.map((item) => {
				return item.children;
			})
			.join('|');

		// RENTAL CONTRACT DURATION
		const date_from = new Date(fromDate);
		const date_to = new Date(toDate);
		const diffTime = Math.abs(date_to - date_from);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		const dta = Math.abs(date_from - Date.now());
		const dtaDiff = Math.ceil(dta / (1000 * 60 * 60 * 24));

		const fbp = getFbClientId();

		const formData = {
			tab: formType,
			vert: store.getState().base.enabledVert,
			'location-name': remove(getCarLocation(data.hotelDestination)),
			'hotel-country': remove(destination.country),
			'state-code': destination.stateCode,
			'country-code': destination.countryCode,
			'check-in-date': formatISO(fromDate),
			'check-out-date': formatISO(toDate),
			'destination-id': pickupDestinationID,
			'destination-key': pickupDestinationKey,
			destination: remove(getCarLocation(data.hotelDestination)),
			city,
			hiata: destination.iata,
			rooms: rooms.length,
			_adults: adults,
			_children: children,
			email: store.getState().base.priceAlertEmail,
			los: diffDays,
			dta: dtaDiff,
			hid: destination.clicktripzHotelId,
			...(fbp && { fbp }),
		};
		// if (destination.hasOwnProperty('iata')) {
		//     formData['hiata'] = destination.iata
		//     formData['city'] = remove(destination.city)
		// } else {
		//     formData['city'] =remove (city)
		// }
		return formData;
	});
};

export const prePopulateDataFromQueryString = () =>
	catchErrorSync(() => {
		const parsed = Helper.parseQueryString(window.location.search, true);
		if (parsed.hasOwnProperty('date-from') && parsed['date-from']) {
			let dateFrom = parsed['date-from'];
			if (Helper.isValidDateFormat(dateFrom)) {
				dateFrom = Helper.convertStringDateToDateObject(dateFrom);
				store.dispatch(setCheckInDate(dateFrom));
			}
		}
		if (parsed.hasOwnProperty('date-to') && parsed['date-to']) {
			let dateTo = parsed['date-to'];
			if (Helper.isValidDateFormat(dateTo)) {
				dateTo = Helper.convertStringDateToDateObject(dateTo);
				store.dispatch(setCheckOutDate(dateTo));
			}
		}
	});
