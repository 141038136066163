import Image from 'next/image';
import React from 'react';
import { Navbar } from 'react-bootstrap';

class Header extends React.PureComponent {
	render() {
		return (
			<div>
				<Navbar expand="lg">
					<div className="container">
						<div className="row  justify-content-center align-items-center" style={{ width: '100%' }}>
							<div className="col-12 text-md-start text-center navbar-parent ">
								<Navbar.Brand href="/">
									<Image
										width={160}
										height={40}
										style={{ objectFit: 'contain' }}
										alt={process.env.NEXT_PUBLIC_SITE_NAME as string}
										src="/images/logos/new-logo.webp"
										className="Logo"
									/>
								</Navbar.Brand>
							</div>
						</div>
					</div>
				</Navbar>
			</div>
		);
	}
}

export default React.memo(Header);
