import React, { FC } from 'react';
import Helper from '../../../modules/Helper';
import Trans from '../../Common/Trans';

const Footer: FC = () => {
	return (
		<footer className="footer">
			<div className="container ">
				<div className="row " style={{ minHeight: '220px' }}>
					<div
						className="col-12 text-center pt-5 "
						style={{
							position: 'relative',
							overflow: 'hidden',
						}}
					>
						<ul id="footer-links">
							<li>
								<a target="_blank" href={Helper.getLink('/contact-us')}>
									<Trans i18nKey="keywords.contactUs" />
								</a>
							</li>
							<li>
								<a target="_blank" href={Helper.getLink('/terms')}>
									<Trans i18nKey="keywords.termsOfUser" />
								</a>
							</li>
							<li>
								<a target="_blank" href={Helper.getLink('/privacy')}>
									<Trans i18nKey="keywords.privacyPolicy" />
								</a>
							</li>
						</ul>
					</div>
					<br />

					<div className="col-12 text-center  pt-4 ">
						<p>
							<Trans i18nKey="main.footerAboutUs" />
						</p>
						<p>
							<Trans i18nKey="main.footerCopyrightText" />
						</p>
					</div>
				</div>
			</div>
		</footer>
	);
};
export default React.memo(Footer);
